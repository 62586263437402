import React from "react";
import { Box, Container, SxProps } from "@mui/material";
import colors from "@/lib/palette";

type Props = {
  children: React.ReactNode;
  bgcolor?: keyof typeof colors;
  containerVariant?: "primary" | "secondary";
  sx?: SxProps;
  className?: string;
};

const SectionsComponent = ({
  children,
  bgcolor = "black100",
  sx,
  containerVariant = "primary",
  className,
}: Props) => {
  return (
    <Box
      component="section"
      bgcolor={colors[bgcolor]}
      className={className}
      sx={{
        position: "relative",

        padding: {
          xs: "50px 0",
          sm: "54px 0",
          md: "57px 0",
          lg: "60px 0",
          xl: "100px 0",
        },

        ...sx,
      }}
    >
      <Container
        sx={
          containerVariant === "secondary"
            ? { px: { xs: "8px", md: "24px" } }
            : {}
        }
      >
        {children}
      </Container>
    </Box>
  );
};

export default SectionsComponent;
