"use client";
import colors from "@/lib/palette";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextFieldProps,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import React, { useState } from "react";

const passwordRules = [
  { label: "One uppercase character", regex: /[A-Z]/ },
  { label: "One lowercase character", regex: /[a-z]/ },
  { label: "One digit", regex: /\d/ },
  { label: "8 characters minimum", regex: /.{8,}/ },
  { label: "One special symbol", regex: /[!@#$%^&*(),.?":{}|<>]/ },
];

function PasswordInputComponent(
  props: TextFieldProps & { passwordType?: "primary" | "secondary" }
) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (props.onChange) props.onChange(event);
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  if (props.passwordType === "secondary") {
    return (
      <Box position="relative">
        <OutlinedInput
          type={showPassword ? "text" : "password"}
          placeholder={props.placeholder || "Password"}
          disabled={props.disabled}
          error={props.error}
          onChange={handlePasswordChange}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          value={password}
          required={props.required}
          name={props.name}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={
                  showPassword ? "hide the password" : "display the password"
                }
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          fullWidth
        />
        {focused && (
          <List
            sx={{
              // position: "absolute",
              // left: 0,
              width: "100%",
              // bgcolor: "white",
              borderRadius: "4px",
              // boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
              padding: "8px",
              mt: "4px",
              display: "grid",
              gap: "8px",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {passwordRules.map(({ label, regex }) => (
              <ListItem key={label}>
                <Typography
                  sx={{
                    textDecoration: regex.test(password)
                      ? "line-through"
                      : "none",
                    ...(regex.test(password) && { color: colors.black700 }),

                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                    },
                    fontWeight: 400,
                  }}
                >
                  • {label}
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <OutlinedInput
        type={password ? "text" : "password"}
        id={props.id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        error={props.error}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        value={props.value}
        required={props.required}
        name={props.name}
        ref={props.ref}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={
                password ? "hide the password" : "display the password"
              }
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end"
            >
              {!password ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      {props.helperText && (
        <Typography
          component={"p"}
          sx={{
            color: "#d32f2f",
            fontWeight: 400,
            fontSize: "12px !important",
            lineHeight: 1.66,
            textAlign: "left",
            marginTop: "3px",
            marginBottom: 0,
            marginLeft: "14px",
            marginRight: "14px",
          }}
        >
          {props.helperText}
        </Typography>
      )}
    </Box>
  );
}

export default PasswordInputComponent;
